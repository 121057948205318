import React from 'react';
import Technologies from '../../Components/Technologies/Technologies';
import SEO from '../../Common/Components/SEO/SEO';
import { ReactTyped } from "react-typed";
import './Home.css';

const Home = () => {
    return (
        <>
            <SEO
                title="SharePoint Consulting & Enterprise Products Development Company | NextAspect Technologies"
                description="NextAspect Technologies offers web development, SharePoint solutions, and custom IT services tailored to your business needs."
                canonical="https://nextaspecttech.com/"
            />
            <div className="home-container">
                <video autoPlay loop muted className="background-video">
                    <source src={require('../../Assets/videos/Main.mp4')} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="centered-text text-white">
                    <p className='font-semiBold white-space-nowrap text-6xl'>Premium IT Solutions Crafted for Your Unique Needs&nbsp;</p>
                    <h2>
                        <ReactTyped
                            style={{ color: '#f5bb0c' }}
                            strings={['Web Development', 'Custom Development', 'Product Development', 'UX/UI Design', 'Software Testing & QA', 'IT Consultancy']}
                            typeSpeed={100}
                            backSpeed={50}
                            loop
                        />
                    </h2>
                    {/* <p>From Vision to Virtual Reality</p> */}
                </div>
            </div>
            <Technologies />
        </>
    );
};

export default Home;