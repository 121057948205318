import React, { useContext } from 'react';
import { Dialog, Button } from 'primereact';
import { AppContext } from '../../Contexts/AppContext';

const ThankyouDialog = () => {
    const { thankyouContent, thankyouDialog, setThankyouDialog } = useContext(AppContext);

    const footer = (
        <div className='text-center'>
            <Button size='small' label="Schedule a meeting" icon="pi pi-arrow-right" iconPos='right' className="p-button-success" />
            <Button size='small' label="Skip, I'll confirm later" className="p-button-text" onClick={() => setThankyouDialog(false)} />
        </div>
    );

    return (
        <Dialog
            header={<div style={{ textAlign: 'center', width: '100%' }}>Thank you!</div>}
            draggable={false}
            visible={thankyouDialog}
            style={{ width: '40vw' }}
            footer={footer}
            onHide={() => setThankyouDialog(false)}
        >
            <div className="flex justify-content-center text-center align-items-center" style={{ flexDirection: 'column' }}>
                <img src={require('../../Assets/images/contactUs/success.gif')} alt="thankyou" style={{ width: '14%' }} />
                <div dangerouslySetInnerHTML={{ __html: thankyouContent[0]?.Description }}></div>
            </div>
        </Dialog>
    );
};

export default ThankyouDialog