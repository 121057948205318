import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import SEO from "../../Common/Components/SEO/SEO";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { AppContext } from "../../Contexts/AppContext";
import { tryParseJSON } from "../../Common/commonFunction";
import './Sitemap.css';

const Sitemap = () => {
    const { sitemapContent } = useContext(AppContext);

    return (
        <>
            <SEO
                title="Sitemap | NextAspect Technologies"
                description="Explore NextAspect Technologies services, products, technologies, work portfolio, and insights, including our innovative Smart HelpDesk for SharePoint solutions"
                keywords="Sitemap, NextAspect Technologies sitemap, SharePoint solutions, Custom solutions, Company insights, Digital solutions sitemap, About NextAspect Technologies, SPFx development, "
                canonical="https://nextaspecttech.com/sitemap"
            />
            <Breadcrumb Title="WebMap Assistant" SubTitle="Sitemap" Description="Your personalized tool for seamless website exploration. Find your way quickly and effortlessly." />
            <div className="container py-7">
                {sitemapContent && sitemapContent.length > 0 && sitemapContent.map(({ Title, Path, SubLinks }) => (
                    <div key={Title} className="sitemap-box">
                        <h3><Link to={Path}>{Title}</Link></h3>
                        <ul className="flex">
                            {SubLinks && tryParseJSON(SubLinks)?.map(({ Title, Path }) => (
                                <li key={Title}><Link to={Path}>{Title}</Link></li>
                            ))}
                        </ul>
                    </div>
                ))}
                {/* <div className="sitemap-box">
                    <h3><a href="http://mobmaxime.xamarin.online/portfolio/">Our Work/Success Stories</a></h3>
                    <ul className="d-md-flex">
                        <li><a href="../web-development/index.html">Portfolio</a></li>
                        <li><a href="../web-development/index.html">Testimonials</a></li>
                    </ul>
                </div> */}
                {/* <div className="sitemap-box">
                    <h3><a href="http://mobmaxime.xamarin.online/portfolio/">Blogs</a></h3>
                    <ul className="d-md-flex">
                    </ul>
                </div> */}
                {/* <div className="sitemap-box">
                    <h3><a href="http://mobmaxime.xamarin.online/case-study/">Case-Study</a></h3>
                    <ul className="d-md-flex">
                    </ul>
                </div>
                <div className="sitemap-box">
                    <h3><a href="http://mobmaxime.xamarin.online/case-study/">Technologies</a></h3>
                    <ul className="d-md-flex">
                    </ul>
                </div> */}
                {/* <div className="sitemap-box">
                    <h3><Link to='/contact-us'>Contact Us</Link></h3>
                    <ul className="d-md-flex">
                    </ul>
                </div> */}
            </div>
        </>
    );
};

export default Sitemap;