// src/Content.js
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import './Content.css'

const contentData = {
   "getting-started": {
      title: "Getting Started",
      content: `<h1 align="center" style="box-sizing: inherit; font-size: 2em; line-height: 1.3; margin: 0px 0px 1.25em; letter-spacing: -0.01em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">Deskzai - Customer Support System</h1>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">This document efficiently manage and resolve customer inquiries with our robust support ticket system, streamlining communication and enhancing user satisfaction.</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">Introduction<a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/index.html#introduction" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 0.8em 0px; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">What is&#160;<a href="https://zaidesk.zainikthemes.com/" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 125ms;">Deskzai</a>&#160;?<a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/index.html#what_is_deskzai" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;"> 
   <strong>
      <a href="https://zaidesk.zainikthemes.com/" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 125ms;">Deskzai</a></strong>&#160;Revolutionizing Support Ticket Systems with Seamless Collaboration.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">In the fast-paced world of customer service and technical support, efficient communication and streamlined processes are essential for delivering exceptional user experiences. This is where Deskzai, a cutting-edge support ticket system, steps in as a game-changer. Deskzai introduces a comprehensive and intuitive solution that connects administrators, customers, and agents through a well-integrated platform, enhancing communication, issue resolution, and overall customer satisfaction.</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">How&#160;<a href="https://zaidesk.zainikthemes.com/" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 125ms;">Deskzai</a>&#160;Works?<a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/index.html#how_deskzai_works" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">This platform has three panel (Admin, Agent, Customer) and description here</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Deskzai acts as a unified hub where administrators, customers, and agents converge to address and resolve support-related matters seamlessly. With a user-friendly interface designed for ease of use, users can effortlessly navigate through the system&#39;s functionalities, ensuring that every interaction is efficient and productive.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;"> 
   <strong>
      <a href="https://zaidesk.zainikthemes.com/" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 125ms;">Admin</a></strong></p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin maintain user accounts and permissions, gain insights from comprehensive analytics, customize the platform to align with organizational needs, and ensure the overall performance and security of the Deskzai system.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;"> 
   <strong>
      <a href="https://zaidesk.zainikthemes.com/" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 125ms;">Agent</a></strong></p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Agents have the capability to manage assigned tickets efficiently, collaborate with fellow agents through internal discussions, access a knowledge base for quick solutions, and deliver consistent responses using predefined templates.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;"> 
   <strong>
      <a href="https://zaidesk.zainikthemes.com/" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 125ms;">Customer</a></strong></p>
<p style="box-sizing: inherit; margin-bottom: 0px; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Customers can effortlessly create support tickets, engage in real-time chats for immediate assistance, track ticket progress, and provide valuable feedback on their experience within the Deskzai platform.<br/></p>
<p> 
   <br/> 
</p>`
   },
   "admin-panel": {
      title: "Admin Panel",
      content: `<h1 style="box-sizing: inherit; font-size: 2em; line-height: 1.3; margin: 0px 0px 1.25em; letter-spacing: -0.01em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">Super Admin Account Activities<a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#super_admin_account_activities" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h1>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Super Admin Dashboard</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#super_admin_dashboard" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can show</p>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0.5em; margin-left: 1.25em;">
      <p style="box-sizing: inherit; margin-top: 0.5em; margin-bottom: 0.5em;">
         <strong style="box-sizing: inherit;">New User</strong></p>
   </li>
   <li style="box-sizing: inherit; margin-bottom: 0.5em; margin-left: 1.25em;">
      <p style="box-sizing: inherit; margin-top: 0.5em; margin-bottom: 0.5em;">
         <strong style="box-sizing: inherit;">Suspended User</strong></p>
   </li>
   <li style="box-sizing: inherit; margin-bottom: 0.5em; margin-left: 1.25em;">
      <p style="box-sizing: inherit; margin-top: 0.5em; margin-bottom: 0.5em;">
         <strong style="box-sizing: inherit;">Deleted User</strong></p>
   </li>
   <li style="box-sizing: inherit; margin-bottom: 0.5em; margin-left: 1.25em;">
      <p style="box-sizing: inherit; margin-top: 0.5em; margin-bottom: 0.5em;">
         <strong style="box-sizing: inherit;">Pending Order</strong></p>
   </li>
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <p style="box-sizing: inherit; margin-top: 0.5em; margin-bottom: 0.5em;">
         <strong style="box-sizing: inherit;">Monthly Order Summery</strong></p>
   </li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/Zaidesk-Dashboard.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">User</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#user" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <strong style="box-sizing: inherit;">Super Admin can view all user from it add, edit ,view ,delete and suspend all user.</strong></li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-1.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <strong style="box-sizing: inherit;">Add User</strong></li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-2.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Subscription</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#subscription" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 0.8em 0px; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Packages</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#packages" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can add, edit and delete packages as per his wish from this panel. Or you can see the list of all packages</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-3.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">All Order</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#all_order" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0.5em; margin-left: 1.25em;">
      <strong style="box-sizing: inherit;">In this panel user can see all check out or plan purchase history.</strong></li>
   <li style="box-sizing: inherit; margin-bottom: 0.5em; margin-left: 1.25em;">
      <strong style="box-sizing: inherit;">You can see all the information of the order in five steps, and if you click on their slip download, view button you can see them.</strong></li>
   <li style="box-sizing: inherit; margin-bottom: 0.5em; margin-left: 1.25em;">
      <p style="box-sizing: inherit; margin-top: 0.5em; margin-bottom: 0.5em;">
         <strong style="box-sizing: inherit;">If you click on edit button you can pay or cancel the pending orders. Or you can see all that information in these steps:</strong></p>
   </li>
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <p style="box-sizing: inherit; margin-top: 0.5em; margin-bottom: 0.5em;">
         <strong style="box-sizing: inherit;">All Orders history</strong></p>
   </li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-4.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <strong style="box-sizing: inherit;">All Orders paid history</strong></li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-5.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <strong style="box-sizing: inherit;">All Orders pending history</strong></li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-6.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <strong style="box-sizing: inherit;">All Orders bank pending history</strong></li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-7-.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <strong style="box-sizing: inherit;">All Orders cancel history</strong></li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-8.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Custom Domain</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#custom_domain" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 0.8em 0px; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Request List</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#request_list" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">Here all user will setup domain in his account, list of all will be shown here and he can approve or cancel by clicking this&#160;<strong style="box-sizing: inherit;">action</strong>&#160;button.</li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-34.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Setup Instruction</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#setup_instruction" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <strong style="box-sizing: inherit;">Here all users will set up their domain in their account, then if they want to send any message or any message, they can send it from this place.</strong></li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-10.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Settings</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#settings" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 0.8em 0px; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">General Settings</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#general_settings" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <h4 style="box-sizing: inherit; font-weight: 700; letter-spacing: -0.01em; margin: 1em 0px;">
         <strong style="box-sizing: inherit;">Application Settings</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#application_settings" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h4>
   </li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can manage Application Setting from this menu bar and update for&#160;<strong style="box-sizing: inherit;">App Name, App Email, App Contact Number, App Location, App Copyright, Developed By, Timezone</strong>.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-11.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <h4 style="box-sizing: inherit; font-weight: 700; letter-spacing: -0.01em; margin: 1em 0px;">
         <strong style="box-sizing: inherit;">Theme Settings</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#theme_settings" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h4>
   </li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can manage Site Logo Setting from this menu bar and update for&#160;<strong style="box-sizing: inherit;">App Preloader, App Logo,App Fav Icon,Admin Logo &amp; Footer Logo,Login Left Image</strong>.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-12.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <h4 style="box-sizing: inherit; font-weight: 700; letter-spacing: -0.01em; margin: 1em 0px;">
         <strong style="box-sizing: inherit;">Storage Settings</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#storage_settings" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h4>
   </li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can manage Storage Setting from this menu bar and update for&#160;<strong style="box-sizing: inherit;">Public, AWS, Wasabi,Vultr, Digital Ocean(DO)</strong>.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-13.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <h4 style="box-sizing: inherit; font-weight: 700; letter-spacing: -0.01em; margin: 1em 0px;">
         <strong style="box-sizing: inherit;">Maintenance Mode</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#maintenance_mode" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h4>
   </li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can manage Maintenance Mode from this menu bar and update for&#160;<strong style="box-sizing: inherit;">Maintenance Mode, Maintenance Mode Secret Key,Maintenance Mode Url</strong>.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-14.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0.5em; margin-left: 1.25em;">
      <h4 style="box-sizing: inherit; font-weight: 700; letter-spacing: -0.01em; margin: 1em 0px;">
         <strong style="box-sizing: inherit;">Cache Settings</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#cache_settings" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h4>
   </li>
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <p style="box-sizing: inherit; margin-top: 0.5em; margin-bottom: 0.5em;">Super Admin can manageCache Settings from this menu bar and update.</p>
   </li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-15.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Configuration Setting</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#configuration_setting" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can manage Configuration Setting this menu bar and update and configure for&#160;<strong style="box-sizing: inherit;">Email Verification,E-mail credentials status,Pusher credentials status,Social Login (Google),Social Login (Facebook),Google Recaptcha Credentials,Google Analytics,Chat GTP-4 Api Key,Chat Setting,Email Ticket Configuration,Preloader</strong>.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-16.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Payment Gateway</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#payment_gateway" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can view and manage Payment Gateway from this menu bar and edit for all Payment gateway by&#160;<strong style="box-sizing: inherit;">clicking Action</strong>.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-17.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Language Settings</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#language_settings" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can view and manage Language Settings from this menu bar and add new Language , edit or delete by&#160;<strong style="box-sizing: inherit;">clicking Action</strong>.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-16.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <strong style="box-sizing: inherit;">Translate language</strong></li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-19.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Country Settings</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#country_settings" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-20.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Email Template</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#email_template" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can view and manage Email Template from this menu bar and customize for a Email Template by&#160;<strong style="box-sizing: inherit;">clicking Action</strong>.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-21.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Cms Settings</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#cms_settings" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 0.8em 0px; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Basic Cms Settings</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#basic_cms_settings" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can update all&#160;<strong style="box-sizing: inherit;">Auth Page Title,Auth Page Subtitle,Footer Left Text,{Social Media Profile Link (Footer)[Facebook URL,Instagram URL,LinkedIn URL,Twitter URL,Skype Url]}</strong></p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-22.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Section Settings</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#section_settings" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can update all Frontend Section Title, Page Title , Description , Image,</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-23.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Features</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#features" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can edit Features as per his wish from this panel. Or you can see the list of all Features.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-24.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Faq Category</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#faq_category" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can add, edit and delete Faq Category as per his wish from this panel. Or you can see the list of all Faq Category.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-25.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Faq</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#faq" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can add, edit and delete Faq as per his wish from this panel. Or you can see the list of all Faq.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-26.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Testimonial</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#testimonial" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can add, edit and delete Testimonial as per his wish from this panel. Or you can see the list of all Testimonial.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-27.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Pages</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#pages" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can edit Pages as per his wish from this panel. Or you can see the list of all Pages.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-28.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Knowledge Category</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#knowledge_category" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can add, edit and delete Knowledge Category as per his wish from this panel. Or you can see the list of all Knowledge Category.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-29.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Knowledge</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#knowledge" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can add, edit and delete Knowledge as per his wish from this panel. Or you can see the list of all Knowledge.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-30.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Custom Page</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#custom_page" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 0.8em 0px; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Pages</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#pages_1" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can add, edit and delete Custom Pages as per his wish from this panel. Or you can see the list of all Custom Pages.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-31.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Contact Messages</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#contact_messages" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can show this all contact messages and edit this messages.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="file:///F:/Project/HTML/HelpCenter/images/super-admin/s-a-32.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 60px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Version Update</strong><a class="headerlink" href="file:///F:/Project/HTML/HelpCenter/super-admin.html#version_update" title="Permanent link" style="box-sizing: inherit; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Super Admin can Update version in this project.</p>
<p style="box-sizing: inherit; margin-bottom: 0px; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://deskzai-doc.zainikthemes.com/images/admin-img/a-12.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px;"/>
   <br/>
</p>
<h1 align="center" style="box-sizing: inherit; font-size: 2em; line-height: 1.3; margin: 0px 0px 1.25em; letter-spacing: -0.01em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <br/>
</h1>`
   },
   "super-admin-panel": {
      title: "Super Admin Panel",
      content: "The Super Admin Panel provides advanced options..."
   },
   "agent-panel": {
      title: "Agent Panel",
      content: "The Agent Panel is designed for support agents..."
   },
   "customer-panel": {
      title: "Customer Panel",
      content: "The Customer Panel is for end users..."
   },
   "custom-domain-setup": {
      title: "Custom Domain Setup",
      content: "Steps to set up a custom domain..."
   },
   "faq": {
      title: "FAQ",
      content: "Frequently Asked Questions..."
   },
   "others": {
      title: "Others",
      content: "Other important sections..."
   },
   "addon-install": {
      title: "Addon Install",
      content: "Instructions for installing addons..."
   },
   "version-update-manually": {
      title: "Version Update Manually",
      content: "How to update the version manually..."
   },
   "configurable": {
      title: "Configurable",
      content: "Configurable settings and options..."
   },
   "email-ticket-configure": {
      title: "Email Ticket Configure",
      content: "How to configure email tickets..."
   },
   "change-log": {
      title: "Change Log",
      content: "Changes and updates in each version..."
   },
};

const Content = () => {
   const { section } = useParams();
   const data = contentData[section] || {};
   // useEffect(() => {
   //    // Function to scroll to the section specified in the URL fragment
   //    const scrollToFragment = () => {
   //       const hash = window.location.hash;
   //       if (hash) {
   //          const element = document.querySelector(hash);
   //          if (element) {
   //             element.scrollIntoView({ behavior: 'smooth' });
   //          }
   //       }
   //    };

   //    // Call the scroll function on mount
   //    scrollToFragment();

   //    // Optionally, handle hash change if needed
   //    window.addEventListener('hashchange', scrollToFragment);

   //    // Cleanup event listener
   //    return () => {
   //       window.removeEventListener('hashchange', scrollToFragment);
   //    };
   // }, [section]);

   // console.log("section..", section);
   // console.log("data..", data);

   return (
      // <div className='flex'>
      //     <div className="content">
      //         <h1>{data.title}</h1>
      //         <div>
      //             <div id='section1'><h1>demo1</h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, voluptatibus! Accusamus non, quos itaque quibusdam veritatis modi, ipsam natus possimus doloremque optio omnis, quisquam deserunt eum similique! Quasi nesciunt ratione non.</div>
      //             <br />
      //             <div id="section2"><h1>demo2</h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non recusandae aliquam nihil magnam minus, cupiditate ea voluptatibus magni culpa. Magni quibusdam laborum quisquam eligendi. Pariatur est ex corporis amet dolor fuga eaque alias at sapiente sunt? Ex dolores blanditiis esse! Quae doloribus porro.</div>
      //             <br />
      //             <div id="section3"><h1>demo3</h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non recusandae aliquam nihil magnam minus, cupiditate ea voluptatibus magni culpa. Magni quibusdam laborum quisquam eligendi. Pariatur est ex corporis amet dolor fuga eaque alias at sapiente sunt? Ex dolores blanditiis esse! Quae doloribus porro.</div>
      //             <br />
      //             <div id="section4"><h1>demo4</h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non recusandae aliquam nihil magnam minus, cupiditate ea voluptatibus magni culpa. Magni quibusdam laborum quisquam eligendi. Pariatur est ex corporis amet dolor fuga eaque alias at sapiente sunt? Ex dolores blanditiis esse! Quae doloribus porro.</div>
      //             <br />
      //             <div id="section5"><h1>demo5</h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non recusandae aliquam nihil magnam minus, cupiditate ea voluptatibus magni culpa. Magni quibusdam laborum quisquam eligendi. Pariatur est ex corporis amet dolor fuga eaque alias at sapiente sunt? Ex dolores blanditiis esse! Quae doloribus porro.</div>
      //         </div>
      //     </div>
      //     <div className="sidebar-new">
      //         <a href="#section1">Section 1</a>
      //         <a href="#section2">Section 2</a>
      //         <a href="#section3">Section 3</a>
      //         <a href="#section4">Section 4</a>
      //         <a href="#section5">Section 5</a>
      //     </div>
      // </div>

      //   <div className='flex'>
      <div className="content-new">
         <h1>{data.title}</h1>
         <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
      </div>
      //   </div>
   );
}

export default Content;