import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './CookieConsent.css'; // Import external CSS
import { Link } from 'react-router-dom';

const CookieConsent = () => {
    const [showConsent, setShowConsent] = useState(false);

    useEffect(() => {
        // Check if the user has already accepted the cookies
        const consent = Cookies.get('cookieConsent');
        if (!consent) {
            setShowConsent(true);
        }
    }, []);

    const handleAccept = () => {
        // Set cookie for user consent
        Cookies.set('cookieConsent', 'true', { expires: 365 });
        setShowConsent(false);
    };

    const handleDecline = () => {
        // Set cookie for user consent
        Cookies.set('cookieConsent', 'false', { expires: 365 });
        setShowConsent(false);
    }
    if (!showConsent) return null;

    return (
        <div className="cookie-consent-container">
            <div className="cookie-text md:w-8">
                <h3>We Use Cookies, Here's Why</h3>
                <p className='mb-0 font-semibold'>We use cookies to improve your experience on our website. By clicking “Accept”, you agree to the use of cookies as outlined in our <Link to="/privacy-policy">Cookie Policy</Link>.</p>
            </div>
            <div className="cookie-actions">
                <button className="action-btn allow-btn" onClick={handleAccept}>Allow all</button>
                <button className="action-btn deny-btn" onClick={handleDecline}>Deny</button>
            </div>
        </div>
    );
};

export default CookieConsent;