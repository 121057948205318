import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumb.css';

const Breadcrumb = (props) => {
    const { Title, SubTitle, Description } = props;

    return (
        <section className="breadcrumbs">
            {/* <div className="breadcrumb-sm-images">
                <div className="inner-banner-1 magnetic-item">
                    <img src={require('../../Assets/images/Breadcrumb/inner-banner-1.png')} alt="" />
                </div>
                <div className="inner-banner-2 magnetic-item">
                    <img src={require('../../Assets/images/Breadcrumb/inner-banner-2.png')} alt="" />
                </div>
            </div> */}
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb-wrapper">
                            <div className="breadcrumb-cnt">
                                {Title && <span>{Title}</span>}
                                {SubTitle && <h1>{SubTitle}</h1>}
                                {Description && <p style={{ color: '#fff' }}>{Description}</p>}
                                <div className="breadcrumb-list">
                                    <Link to="/">Home</Link><img src={require('../../Assets/images/Breadcrumb/breadcrumb-arrow.svg').default} alt="" />
                                    {SubTitle}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Breadcrumb