import React, { useContext, useEffect, useState } from 'react'
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import { AppContext } from '../../Contexts/AppContext';
import SEO from '../../Common/Components/SEO/SEO';
import { termsAndConditionsData } from '../../Common/constant';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
    const { termsAndConditionsContent } = useContext(AppContext);
    const [terms, setTerms] = useState([]);

    useEffect(() => {
        if (termsAndConditionsContent && termsAndConditionsContent.length > 0) {
            setTerms(termsAndConditionsContent);
        } else {
            setTerms(termsAndConditionsData);
        }
    }, [termsAndConditionsContent]);

    return (
        <div>
            <SEO
                title="Terms and Conditions | NextAspect Technologies"
                description="Review the Terms and Conditions for using NextAspect Technologies. Understand your rights and responsibilities when accessing our services and content."
                canonical="https://nextaspecttech.com/terms-conditions"
            />
            <Breadcrumb Title="LEGAL DISCLAIMER" SubTitle="Our Terms & Conditions" Description="We prioritize transparency and protect your privacy without compromise." />
            <div className="container p-5">
                <h3 style={{ fontSize: '20px', fontWeight: 'bold', color: '#333' }} className='text-center mb-5'>Terms and Conditions</h3>
                <div className="terms-list">
                    {terms && terms.length > 0 && terms.map(({ Title, Content }) => (
                        <li key={Title} className='list-none mb-5'>
                            <h3 data-aos="fade-up">{Title}</h3>
                            <p dangerouslySetInnerHTML={{ __html: Content }} data-aos="fade-up" data-aos-delay="200" />
                        </li>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;