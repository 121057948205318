import { useContext, useState } from "react";
import { Menu, Sidebar, MenuItem, SubMenu } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useSidebarContext } from "./SidebarContext";
import './MyProSidebar.css';

const Item = ({ title, to, icon, selected, setSelected }) => {
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: selected === title ? '#f5bb0c' : 'white', // Change text color when active
                backgroundColor: selected === title ? '#011923' : 'transparent' // Change background color when active
            }}
            title={title}
            onClick={() => setSelected(title)}
            icon={icon}
            routerLink={<Link to={to} />}
        >
            <p className="mb-0">{title}</p>
        </MenuItem>
    );
};

const MyProSidebar = () => {
    const [selected, setSelected] = useState("Dashboard");
    const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
    const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();

    return (
        <div className="side-bar-menu">
            <Sidebar
                defaultCollapsed={false}
                breakPoint="md"
                rtl={sidebarRTL}
                // backgroundColor="#0b2947"
                backgroundColor="#002433"
                image={sidebarImage}
                transitionDuration={1000}
            >
                <Menu renderMenuItemStyles={() => ({
                    '.menu-anchor': {
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    },
                })}>
                    <MenuItem
                        style={{
                            height: '4rem',
                            marginBottom: "20px",
                            background: '#002433'
                        }}
                    >
                        {!collapsed && (
                            <div>
                                <Link to={"/"}><img src={require('../../Assets/images/FinalLogo.png')} alt="logo" /></Link>
                                {/* {broken && <i className="pi pi-times text-white" onClick={broken ? () => toggleSidebar() : () => collapseSidebar()} />} */}
                            </div>
                        )}
                    </MenuItem>
                    {/* <div style={{ paddingLeft: collapsed ? undefined : '10%' }}> */}
                    <div>
                        <h5 className="menu-header">
                            FAQS DOCUMENTATION
                        </h5>
                        <Item
                            title="Introduction"
                            to="getting-started"
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Getting Started"
                            to="admin-panel"
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <h5 className="menu-header">
                            INSTALLATION
                        </h5>
                        <SubMenu title="Installation" label="Installation" className="custom-submenu" >
                            <Item
                                title="Installing"
                                to="admin-panel"
                                selected={selected}
                                icon={<i className="fa fa-genderless" aria-hidden="true" />}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Upgrading"
                                to="admin-panel"
                                selected={selected}
                                icon={<i className="fa fa-genderless" aria-hidden="true" />}
                                setSelected={setSelected}
                            />
                        </SubMenu>
                        <h5 className="menu-header">
                            USER GUIDE
                        </h5>
                        <SubMenu title="User Guide" label="User Guide" className="custom-submenu">
                            <Item
                                title="User Introduction"
                                to="admin-panel"
                                selected={selected}
                                icon={<i className="fa fa-genderless" aria-hidden="true" />}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Getting Started"
                                to="admin-panel"
                                selected={selected}
                                icon={<i className="fa fa-genderless" aria-hidden="true" />}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Home Page"
                                to="admin-panel"
                                selected={selected}
                                icon={<i className="fa fa-genderless" aria-hidden="true" />}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Help"
                                to="admin-panel"
                                selected={selected}
                                icon={<i className="fa fa-genderless" aria-hidden="true" />}
                                setSelected={setSelected}
                            />
                        </SubMenu>
                        <h5 className="menu-header">
                            ADMIN GUIDE
                        </h5>
                        <SubMenu title="Admin Guide" label="Admin Guide" className="custom-submenu">
                            <Item
                                title="Admin Introduction"
                                to="admin-panel"
                                selected={selected}
                                icon={<i className="fa fa-genderless" aria-hidden="true" />}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Architecture Diagram"
                                to="admin-panel"
                                selected={selected}
                                icon={<i className="fa fa-genderless" aria-hidden="true" />}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Home Page"
                                to="admin-panel"
                                selected={selected}
                                icon={<i className="fa fa-genderless" aria-hidden="true" />}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Dashboard"
                                to="admin-panel"
                                selected={selected}
                                icon={<i className="fa fa-genderless" aria-hidden="true" />}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Incident"
                                to="admin-panel"
                                selected={selected}
                                icon={<i className="fa fa-genderless" aria-hidden="true" />}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Reports"
                                to="admin-panel"
                                selected={selected}
                                icon={<i className="fa fa-genderless" aria-hidden="true" />}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Comment Page"
                                to="admin-panel"
                                selected={selected}
                                icon={<i className="fa fa-genderless" aria-hidden="true" />}
                                setSelected={setSelected}
                            />
                        </SubMenu>
                    </div>
                </Menu>
            </Sidebar>
        </div>
    );
};

export default MyProSidebar;