export const toSlug = (label) => {
    return label
        .toLowerCase()              // Convert to lowercase
        .replace(/[\/\s]+/g, '-')   // Replace slashes and spaces with hyphens
        .replace(/[^\w\-]+/g, '')   // Remove non-word characters
        .replace(/\-\-+/g, '-');    // Replace multiple hyphens with a single hyphen
};

export const tryParseJSON = (jsonString) => {
    try {
        const parsed = JSON.parse(jsonString);
        if (parsed && typeof parsed === 'object') {
            return parsed;
        }
    } catch (e) {
        return null;
    }
    return null;
};
