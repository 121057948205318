import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="help-center-footer">
            <p className='mb-0 font-medium text-white'>
                Copyright © 2024 By <Link to='https://nextaspecttech.com/' target="_blank" rel="noopener noreferrer"> NextAspect Technologies </Link>| All Rights Reserved.
            </p>
        </footer>
    );
};

export default Footer;
