import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../Contexts/AppContext';
import MagneticHover from '../../Components/MagneticHover/MagneticHover';
import { toSlug } from '../../Common/commonFunction';
import './Products.css';

const Products = () => {
    const { productsContent } = useContext(AppContext);
    return (
        <div className="home3-success-stories-area two sec-mar">
            <div className="container-fluid">
                <div className="row g-4 justify-content-center">
                    <div className="col-lg-12">
                        <div className="section-title-4 two text-center">
                            <span>Solutions We Offer</span>
                            <h2>Transform the Future With Top <span>Innovative</span> Products</h2>
                        </div>
                    </div>
                    {productsContent && productsContent.length > 0 && productsContent.map((ele, index) => {
                        const delay = 200 + index * 100;
                        return (
                            <div key={index} className="col-lg-4 col-md-6 col-sm-10" data-aos="fade-up" data-aos-delay={delay} data-aos-duration={1500}>
                                <div className="success-storie-card">
                                    <div className="success-img">
                                        <MagneticHover>
                                            <img className="img-fluid" src={require('../../Assets/images/products/home3-suc-sto-01.png')} alt="" />
                                        </MagneticHover>
                                    </div>
                                    <div className="success-content">
                                        <span>{ele.Technology}</span>
                                        <h3><Link to={`/products/${toSlug(ele.ProductName)}`}>{ele.ProductName}</Link></h3>
                                        <div className="view-btn">
                                            <Link to={`/products/${toSlug(ele.ProductName)}`}>
                                                <svg width="12" height="12" viewBox="0 0 13 13" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 1H12M12 1V13M12 1L0.5 12"></path>
                                                </svg>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                {/* pagination */}
                {/* <div className="row">
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center">
                            <li className="page-item disabled">
                                <a className="page-link"><i className="pi pi-arrow-left"></i></a>
                            </li>
                            <li className="page-item"><a className="page-link active" href="#">1</a></li>
                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item">
                                <a className="page-link" href="#"><i className="pi pi-arrow-right"></i></a>
                            </li>
                        </ul>
                    </nav>
                </div> */}
            </div>
        </div>
    );
};

export default Products;