import React, { useContext, useEffect, useState } from 'react';
import MagneticHover from '../../Components/MagneticHover/MagneticHover';
import { AppContext } from '../../Contexts/AppContext';
import { tryParseJSON } from '../../Common/commonFunction';
import SEO from '../../Common/Components/SEO/SEO';
import { aboutUsResult } from '../../Common/constant';
import './AboutUs.css';

const AboutUs = () => {
    const { aboutUsContent } = useContext(AppContext);
    const [hoveredBox, setHoveredBox] = useState(null);
    const [whatWeOffer, setWhatWeOffer] = useState([]);
    const [whyChooseUs, setWhyChooseUs] = useState(null);
    const [feature, setFeature] = useState([]);

    const defaultContent = {
        title: "We Are Next Aspect Technology",
        description1: "Welcome to NextAspect Technologies Software. a leading software company specializing in SharePoint, PowerApps, Teams, and Office Add-ins development and consulting. With a team of highly skilled professionals, we are committed to delivering innovative solutions that empower organizations to streamline their processes, enhance collaboration, and maximize productivity.",
        description2: ""
    };

    // Helper function to parse JSON safely and handle fallback data
    const parseAndSetData = (content) => {
        const parsedWhatWeOffer = tryParseJSON(content?.WhatWeOffer);
        const parsedWhyChooseUs = tryParseJSON(content?.WhyChooseUs);
        const parsedFeature = tryParseJSON(content?.Feature);

        setWhatWeOffer(parsedWhatWeOffer || []);
        setWhyChooseUs(parsedWhyChooseUs || []);
        setFeature(parsedFeature || []);
    };

    useEffect(() => {
        // Use Firebase data if available, otherwise fallback to static data
        const content = aboutUsContent?.length > 0 ? aboutUsContent[0] : aboutUsResult[0];
        parseAndSetData(content);
    }, [aboutUsContent]);

    const currentContent = hoveredBox ? whatWeOffer?.find(item => item.key === hoveredBox) : defaultContent;

    return (
        <div>
            <SEO
                title="About Us | NextAspect Technologies - Company Overview"
                description="Discover NextAspect Technologies, a leader in SharePoint consulting, web development, and enterprise IT services, helping businesses innovate, scale, and thrive."
                keywords="NextAspect Technologies, About NextAspect, Company Overview, SharePoint Consulting, Web Development, IT Services, Custom Enterprise Solutions"
                canonical="https://nextaspecttech.com/about-us"
            />
            <section className="about-offer">
                <div className="container">
                    <div className="grid flex-row-reverse">
                        <div className="col-12 align-self-center lg:col-6 md:p-6" data-aos="fade-left">
                            <div className="ot-heading">
                                <span style={{ color: '#f5bb0c' }}>// what we offer</span>
                                <h2 className="main-heading mb-3 text-white">{hoveredBox ? currentContent?.title : defaultContent.title}</h2>
                            </div>
                            <div className="space-5"></div>
                            <p className='text-white'>{hoveredBox ? currentContent?.description1 : defaultContent?.description1}</p>
                            <p><em className="text-white"><strong>{hoveredBox ? currentContent?.description2 : defaultContent?.description2}</strong></em></p>
                            <div className="space-20"></div>
                            {/* <div className="video-popup style-2">
                                <div className="btn-inner">
                                    <a className="btn-play" href="https://www.youtube.com/watch?v=lfDZJqSrIuk">
                                        <i className="pi pi-play-circle"></i>
                                        <span className="circle-1"></span>
                                        <span className="circle-2"></span>
                                    </a>
                                </div>
                                <span style={{ color: '#f5bb0c' }}>video showcase</span>
                            </div> */}
                        </div>
                        <div className="col-12 lg:col-6">
                            <div className="grid md:px-8 md:pt-6">
                                {whatWeOffer && whatWeOffer.length > 0 && whatWeOffer.map((ele, index) => {
                                    return (
                                        <div className="col-12 lg:col-6 md:col-6" key={index} data-aos="fade-right">
                                            <a className={`ot-image-box v3 ${index % 2 == 0 ? 'st3' : 'st4'} mb-0`}
                                                href="#"
                                                onMouseEnter={() => setHoveredBox(ele.key)}
                                                onMouseLeave={() => setHoveredBox(null)}>
                                                <div className="overlay">
                                                    <h4>{ele.title}</h4>
                                                </div>
                                                {aboutUsContent?.length > 0 ? <img src={ele.image} alt={ele.key} /> : <img src={require(`../../Assets/images/AboutUs/${ele.image}`)} alt={ele.key} />}
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="home6-choose-section sec-mar">
                <div className="container">
                    <div className="row">
                        <div className="choose-top">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="choose-title">
                                        <span>Why Choose Us</span>
                                        <h2>{whyChooseUs?.title}</h2>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="choose-right-img">
                                        <MagneticHover>
                                            <img className="img-fluid " src={whyChooseUs?.image} alt="" />
                                        </MagneticHover>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="choose-btm">
                            <div className="row g-lg-4 gy-5">
                                <div className="col-lg-7">
                                    <div className="choose-left-content">
                                        <div className="vectors">
                                            <img className="choose-vec-top-r" src={require('../../Assets/images/AboutUs/choose-vec-top-r.svg').default} alt="" />
                                            <img className="choose-vec-btm-l" src={require('../../Assets/images/AboutUs/choose-vec-btm-l.svg').default} alt="" />
                                        </div>
                                        <div className="icon">
                                            <img src={whyChooseUs?.appLogo} alt="" />
                                        </div>
                                        <h4 data-aos="fade-right">{whyChooseUs?.subTitle}<br />
                                            {/* <span>Since 2005.</span> */}
                                        </h4>
                                        <p data-aos="fade-right">{whyChooseUs?.description}</p>
                                        {/* <div className="sl">
                                            <h2>#1</h2>
                                        </div> */}
                                        {/* <div className="about-btn">
                                            <a href="about.html">
                                                <svg width="7" height="7" viewBox="0 0 13 13" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 1H12M12 1V13M12 1L0.5 12"></path>
                                                </svg>
                                                About More
                                            </a>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="choose-feature">
                                        <ul className='list-none p-0'>
                                            {feature && feature.length > 0 && feature.map((ele, index) => {
                                                return (
                                                    <li className='mb-3' key={index} data-aos="fade-left" data-aos-delay={index * 200}>
                                                        <div className="single-feature">
                                                            <div className="progress">
                                                                <img className='w-7' src={require(`../../Assets/images/AboutUs/${ele.icon}.png`)} alt="" />
                                                            </div>
                                                            <div className="content">
                                                                <h4>{ele.title}</h4>
                                                                <p>{ele.description}</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })};
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;