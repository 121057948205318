import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import css
import 'react-multi-carousel/lib/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/primereact.min.css";
import '../src/Assets/css/saga-green-theme.css';
import 'primeicons/primeicons.css';
import '../node_modules/primeflex/primeflex.css';
import '../src/Assets/css/style.css';
import '../src/Assets/css/responsive.css';
import '../src/Assets/css/common.css';
import './App.css';
import 'aos/dist/aos.css';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <App />
  </HelmetProvider>
);