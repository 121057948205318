import React, { createContext, useEffect, useState } from 'react';
import { getAllItems } from '../Services/firebaseService';
import { Lists } from '../Common/constant';

export const AppContext = createContext(null);

const AppContextProvider = (props) => {
    const [thankyouDialog, setThankyouDialog] = useState(false);
    const [aboutUsContent, setAboutUsContent] = useState([]);
    const [thankyouContent, setThankyouContent] = useState([]);
    const [contactUsContent, setContactUsContent] = useState([]);
    const [servicesContent, setServicesContent] = useState([]);
    const [serviceDetailsContent, setServiceDetailsContent] = useState([]);
    const [productsContent, setProductsContent] = useState([]);
    const [productDetailsContent, setProductDetailsContent] = useState([]);
    const [termsAndConditionsContent, setTermsAndConditionsContent] = useState([]);
    const [privacyPolicyContent, setPrivacyPolicyContent] = useState([]);
    const [FAQsContent, setFAQsContent] = useState([]);
    const [technologiesContent, setTechnologiesContent] = useState([]);
    const [sitemapContent, setSitemapContent] = useState([]);

    const getAboutUsData = async () => {
        const result = await getAllItems(Lists.ABOUT_US);
        setAboutUsContent(result);
    };

    const getThankyouDialogData = async () => {
        const result = await getAllItems(Lists.THANKYOU_DIALOG);
        setThankyouContent(result);
    };

    const getContactUsData = async () => {
        const result = await getAllItems(Lists.CONTACT_US);
        setContactUsContent(result);
    };

    const getServicesData = async () => {
        const result = await getAllItems(Lists.SERVICES);
        setServicesContent(result.sort((a, b) => a.Order - b.Order).filter(ele => ele.IsVisible));
    };

    const getServiceDetailsData = async () => {
        const result = await getAllItems(Lists.SERVICES_DETAILS);
        setServiceDetailsContent(result);
    };

    const getProductsData = async () => {
        const result = await getAllItems(Lists.PRODUCTS);
        setProductsContent(result.sort((a, b) => a.Order - b.Order).filter(ele => ele.IsVisible));
    };

    const getProductDetailsData = async () => {
        const result = await getAllItems(Lists.PRODUCTS_DETAILS);
        setProductsContent(result);
    };

    const getTermsAndConditionsData = async () => {
        const result = await getAllItems(Lists.TERMS_AND_CONDITIONS);
        setTermsAndConditionsContent(result.sort((a, b) => a.Order - b.Order));
    };

    const getPrivacyPolicyData = async () => {
        const result = await getAllItems(Lists.PRIVACY_POLICY);
        setPrivacyPolicyContent(result.sort((a, b) => a.Order - b.Order));
    };

    const getFAQsData = async () => {
        const result = await getAllItems(Lists.FAQS);
        setFAQsContent(result.sort((a, b) => a.Order - b.Order).filter(ele => ele.Active));
    };

    const getTechnologiesData = async () => {
        const result = await getAllItems(Lists.TECHNOLOGIES);
        setTechnologiesContent(result.filter(ele => ele.Active));
    };

    const getSitemapData = async () => {
        const result = await getAllItems(Lists.SITEMAP);
        setSitemapContent(result);
    };

    useEffect(() => {
        getAboutUsData();
        getThankyouDialogData();
        getContactUsData();
        getServicesData();
        getServiceDetailsData();
        getProductsData();
        getTermsAndConditionsData();
        getPrivacyPolicyData();
        getFAQsData();
        getTechnologiesData();
        getSitemapData();
    }, []);

    return (
        <AppContext.Provider value={{
            thankyouDialog,
            setThankyouDialog,
            aboutUsContent,
            thankyouContent,
            contactUsContent,
            servicesContent,
            serviceDetailsContent,
            productsContent,
            productDetailsContent,
            termsAndConditionsContent,
            privacyPolicyContent,
            FAQsContent,
            technologiesContent,
            sitemapContent
        }}>
            {props.children}
        </AppContext.Provider>
    )
}

export default AppContextProvider;