// src/components/MagneticHover.js

import React, { useEffect, useRef } from 'react';
import './MagneticHover.css';

const MagneticHover = ({ children, className = '' }) => {
    const elementRef = useRef(null);

    useEffect(() => {
        const element = elementRef.current;

        const handleMouseMove = (e) => {
            const rect = element.getBoundingClientRect();
            const x = e.clientX - rect.left - rect.width / 2;
            const y = e.clientY - rect.top - rect.height / 2;
            element.style.transform = `translate(${x * 0.1}px, ${y * 0.1}px)`;
        };

        const handleMouseLeave = () => {
            element.style.transform = 'translate(0, 0)';
        };

        element.addEventListener('mousemove', handleMouseMove);
        element.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            element.removeEventListener('mousemove', handleMouseMove);
            element.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, []);

    return (
        <div ref={elementRef} className={`magnetic-item ${className}`}>
            {children}
        </div>
    );
};

export default MagneticHover;