import React from 'react';

const Sections = () => {
    const scrollToSection = (id) => {
        document.querySelector(id).scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div>
            <nav>
                <ul>
                    <li>
                        <button onClick={() => scrollToSection('#section1')}>Section 1</button>
                    </li>
                    <li>
                        <button onClick={() => scrollToSection('#section2')}>Section 2</button>
                    </li>
                    <li>
                        <button onClick={() => scrollToSection('#section3')}>Section 3</button>
                    </li>
                </ul>
            </nav>
            <div style={{ height: '100vh', padding: '50px' }} id='section1'>
                <h2>Section 1</h2>
                <p>This is section 1.</p>
            </div>
            <div style={{ height: '100vh', padding: '50px' }} id='section2'>
                <h2>Section 2</h2>
                <p>This is section 2.</p>
            </div>
            <div style={{ height: '100vh', padding: '50px' }} id='section3'>
                <h2>Section 3</h2>
                <p>This is section 3.</p>
            </div>
        </div>
    );
};

export default Sections;