import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../Contexts/AppContext';
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import { tryParseJSON } from '../../Common/commonFunction';
import { privacyPolicyResults } from '../../Common/constant';
import SEO from '../../Common/Components/SEO/SEO';

const PrivacyPolicy = () => {
    const { privacyPolicyContent } = useContext(AppContext);
    const [policy, setPolicy] = useState(null);

    useEffect(() => {
        const parsedPolicy = tryParseJSON(privacyPolicyContent[0]?.Title);
        console.log("parsedPolicy..", parsedPolicy)
        if (parsedPolicy) {
            setPolicy(parsedPolicy);
        } else {
            setPolicy(privacyPolicyResults);
        }
    }, [privacyPolicyContent]);

    return (
        <>
            <SEO
                title="Privacy Policy | NextAspect Technologies"
                description="We value your privacy. Our policy ensures that your information is never shared or sold to third parties for any purpose. Your data is safe with us!"
                canonical="https://nextaspecttech.com/privacy-policy"
            />
            <Breadcrumb Title="TRANSPARENCY & TRUST" SubTitle="Our Privacy Policy" Description="We believe in maintaining trust and transparency towards our clients, without compromising or disclosing their privacy." />
            <div className="p-5 container">
                <h3 className='text-center mb-5' style={{ fontSize: '20px', fontWeight: 'bold', color: '#333' }} data-aos="fade-up">{policy?.title}</h3>
                {policy?.introduction.map((para, index) => (
                    <p key={index} data-aos="fade-up" data-aos-delay="200" className={`${index == 1 ? 'mb-5' : ''}`} dangerouslySetInnerHTML={{ __html: para }} />
                ))}

                {policy?.sections.map((section, index) => (
                    <div key={index} className='mb-4'>
                        <h3 style={{ fontSize: '20px', fontWeight: 'bold', color: '#333' }} data-aos="fade-up">
                            {section.title}
                        </h3>
                        {section.content.map((para, idx) => (
                            <p className='mb-2' key={idx} data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{ __html: para }} />
                        ))}

                        {section.listItems.length > 0 && (
                            <ul data-aos="fade-up" data-aos-delay="300">
                                {section.listItems.map((item, idx) =>
                                    typeof item === "string" ? (
                                        <li style={{ lineHeight: '1.7' }} key={idx}>{item}</li>
                                    ) : (
                                        // <li key={idx}>
                                        //     <strong>{item.subTitle}</strong>
                                        //     <ul>
                                        //         {item.items.map((subItem, subIdx) => (
                                        //             <li key={subIdx} dangerouslySetInnerHTML={{ __html: subItem }} />
                                        //         ))}
                                        //     </ul>
                                        // </li>

                                        <li key={idx} style={{ lineHeight: '1.7' }}>
                                            <strong>{item.subTitle}:</strong> {/* Adding the colon to label */}
                                            <span dangerouslySetInnerHTML={{ __html: item.items.join(' ') }} /> {/* Concatenating content */}
                                        </li>
                                    )
                                )}
                            </ul>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default PrivacyPolicy;