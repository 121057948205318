// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAuq2FMUxJavrXnlZaW6BNjumoXLij86Os",
    authDomain: "nextaspectsite.firebaseapp.com",
    projectId: "nextaspectsite",
    storageBucket: "nextaspectsite.appspot.com",
    messagingSenderId: "534839652476",
    appId: "1:534839652476:web:a5ac2b697965b1cf1f19f9",
    measurementId: "G-GP7QXGZJ21"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };