import React from 'react';
import { Parallax } from 'react-parallax';
import { Button } from 'primereact';

const ParallaxSection = ({ image, title, subtitle }) => {
    return (
        <Parallax bgImage={image} strength={800}>
            <div className="parallax-content">
                <h1>{title}</h1>
                <p>{subtitle}</p>
                <Button size="small" label="Demo" severity="danger" />
            </div>
        </Parallax>
    );
};

export default ParallaxSection;